import { useEffect, useState } from "react";
import { Row, Container, Col, Button, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import { getFetchParamGenerator, getApiURL } from "../../_services/sat";


const api = SatAPI();

function Reports() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
/*
  const load = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  useEffect(() => {
    load();
  }, [snapshot]);
*/
  const generateDocentenCUperOpleidingExcel = async () => {
    setLoading(true);
    try {
        const { fetchParamCreator, fetch } = getFetchParamGenerator();
        const opts = fetchParamCreator.getDocentenContacturenPerOpleidingReportExcel(snapshot, {});
        const resp = await fetch(getApiURL() + opts.url, opts.options);
        // download content as a file in new tab
        const file = await resp.blob();

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (resp) {
        console.warn(resp);
    }
    setLoading(false);
    };

    const generateDocentenCUperOpleidingPDF = async () => {
      setLoading3(true);
      try {
          const { fetchParamCreator, fetch } = getFetchParamGenerator();
          const opts = fetchParamCreator.getDocentenContacturenPerOpleidingReportPDF(snapshot, {});
          const resp = await fetch(getApiURL() + opts.url, opts.options);
          // download content as a file in new tab
          const file = await resp.blob();
  
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      } catch (resp) {
          console.warn(resp);
      }
      setLoading3(false);
      };

    const generateStaffAssignments = async () => {
      setLoading2(true);
      try {
          const { fetchParamCreator, fetch } = getFetchParamGenerator();
          const opts = fetchParamCreator.getStaffAssignmentReport(99, snapshot, {});
          const resp = await fetch(getApiURL() + opts.url, opts.options);
          // download content as a file in new tab
          const file = await resp.blob();
  
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      } catch (resp) {
          console.warn(resp);
      }
      setLoading2(false);
      };
/*
  if (false) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
    */
  return (
    <Container>
      <Row>
        <Col>
          <h1>Rapportage</h1>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="mt-3 col-5">
          Genereer rapport contacturen docenten per opleiding &nbsp;&nbsp;
          </Col>
          <Col className="mt-3 col-7">
          <Button title="Excel file" variant="success" onClick={() => generateDocentenCUperOpleidingExcel()} disabled={loading || snapshot}>
            
            {loading && 
              <i className="far fa-spinner fa-pulse" />
            }
            {!loading && 
              <i className="far fa-file-excel" />
            }
          </Button>
          &nbsp;
          <Button title="PDF file" variant="danger" onClick={() => generateDocentenCUperOpleidingPDF()} disabled={loading3 || snapshot}>
            
            {loading3 && 
              <i className="far fa-spinner fa-pulse" />
            }
            {!loading3 && 
              <i className="far fa-file-pdf" />
            }
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="mt-3 col-5">
        Testing WIP: geneneer prestatieregeling Jitse &nbsp;&nbsp;
        </Col>
          <Col className="mt-3 col-7">
          <Button title="PDF file" variant="danger" onClick={() => generateStaffAssignments()} disabled={loading2 || snapshot}>
            
            {loading2 && 
              <i className="far fa-spinner fa-pulse" />
            }
            {!loading2 && 
              <i className="far fa-file-pdf" />
            }
          </Button>
        </Col>
      </Row>
      <hr />
    </Container>
  );
}

export default Reports;
